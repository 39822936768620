import React from 'react';
import { Layout } from '../components/Layout';
import { Link } from 'gatsby';

import styled from 'styled-components';
import { SectionHeader } from '../components/SectionHeader';
import { LinkButton } from '../components/LinkButton';
import Img from 'gatsby-image';
import { device } from '../theme';
import { SEO } from '../components/SEO';

const CalculateNumberOfRows = (numberOfItems: any) => {
  switch (true) {
    case numberOfItems <= 4:
      return 1;
      break;
    case numberOfItems <= 6:
      return 2;
      break;
    case numberOfItems <= 8:
      return 3;
      break;
    case numberOfItems <= 10:
      return 4;
      break;
    default:
      return 1;
  }
};

const CalculateNumberOfRowsSmaller = (numberOfItems: any) => {
  switch (true) {
    case numberOfItems <= 2:
      return 1;
      break;
    case numberOfItems <= 4:
      return 2;
      break;
    case numberOfItems <= 6:
      return 3;
      break;
    case numberOfItems <= 8:
      return 4;
      break;
    case numberOfItems <= 10:
      return 5;
      break;
    default:
      return 1;
  }
};
interface SPGProps {
  numberOfItems: number;
}

const StyledPortfolioGrid = styled.div<SPGProps>`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: ${({ numberOfItems }) =>
    `repeat(${CalculateNumberOfRows(numberOfItems)}, minmax(auto, 220px))`};
  gap: 10px;
  a:nth-child(1) {
    grid-column: span 1;
  }
  a:nth-child(5) {
    grid-column: span 3;
  }

  a:nth-child(6) {
    grid-column: span 1;
  }

  a:nth-child(7) {
    grid-column: span 2;
  }

  a:nth-child(8) {
    grid-column: span 2;
  }
  a:nth-child(10) {
    grid-column: span 3;
  }

  @media ${device.tablet} {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: ${({ numberOfItems }) =>
      `repeat(${CalculateNumberOfRowsSmaller(
        numberOfItems
      )}, minmax(auto, 220px))`};
    a:nth-child(1) {
      grid-column: span 1;
    }
    a:nth-child(5) {
      grid-column: span 1;
    }

    a:nth-child(6) {
      grid-column: span 1;
    }

    a:nth-child(7) {
      grid-column: span 1;
    }

    a:nth-child(8) {
      grid-column: span 1;
    }
    a:nth-child(10) {
      grid-column: span 1;
    }
  }
`;

const StyledImageLink = styled(Link)`
  transition: all 0.5s ease 0s;
  &:hover,
  &:focus {
    filter: brightness(0.8);
  }
`;

const StyledImage = styled(Img)`
  transition: all 0.2s ease 0s;
  border-radius: 5px;
  height: 100%;
`;

const StyledPagination = styled.div`
  margin: 20px 0;
  a {
    margin-right: 20px;
  }
`;

const StyledSectionHeader = styled(SectionHeader)`
  /* animation: fadeInSectionHeader ease 2s;
  @keyframes fadeInSectionHeader {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  } */
`;

interface Props {
  pageContext: any;
}
export const PortfolioList = ({ pageContext }: Props) => {
  const {
    portfolioItemsList,
    numPortfolioPages,
    currentPage,
    basePath,
  } = pageContext;
  return (
    <Layout>
      <SEO title="Portfolio" />
      <StyledSectionHeader style={{ marginBottom: '50px' }} forwardedAs="h1">
        Portfolio
      </StyledSectionHeader>
      <StyledPortfolioGrid numberOfItems={portfolioItemsList.length}>
        {portfolioItemsList.map((portItem: any) => {
          return (
            <StyledImageLink
              key={portItem.node.id}
              to={`${basePath}/${portItem.node.slug}`}
            >
              <StyledImage
                fluid={portItem.node.featuredImage.childImageSharp.fluid}
              />
            </StyledImageLink>
          );
        })}
      </StyledPortfolioGrid>
      <StyledPagination>
        {currentPage > 1 && (
          <LinkButton
            to={`${basePath}/portfolio/${
              currentPage === 2 ? '' : currentPage - 1
            }`}
          >
            Previous Page
          </LinkButton>
        )}
        {numPortfolioPages > 1 && currentPage != numPortfolioPages && (
          <LinkButton to={`${basePath}/portfolio/${currentPage + 1}`}>
            Next Page
          </LinkButton>
        )}
      </StyledPagination>
    </Layout>
  );
};

export default PortfolioList;
