import React, { ReactNode } from 'react';
import { Link } from 'gatsby';
import styled, { css } from 'styled-components';
import { themeVariables } from '../../theme';
import { useStaticQuery, graphql } from 'gatsby';

interface SBProps {
  props?: any;
}
const StyledButton = styled(Link).withConfig({
  shouldForwardProp: prop => !['props'].includes(prop),
})<SBProps>`
  padding: 10px 20px;
  border-radius: ${themeVariables.values.borderRadius};
  text-transform: uppercase;
  // transition: all 0.3s ease 0s;
  display: inline-block;
  ${({ props }) =>
    props.primary
      ? css`
          background-color: ${({ theme }) => theme.primary};
          color: ${({ theme }) => theme.primaryButtonText};
        `
      : css`
          border: solid 2px ${({ theme }) => theme.primary};
          color: ${({ theme }) => theme.primary};
        `}
  &:hover,
  &:focus {
    filter: brightness(0.8);
    transform: scale(1.05);
  }
  &:active {
    transform: scale(0.98);
  }
`;
interface Props {
  children: ReactNode;
  to: string;
  primary?: boolean;
}

const LinkButton = ({ children, primary, to = '' }: Props) => {
  const query = useStaticQuery(graphql`
    query getStoriData {
      storiThemeData {
        basePath
      }
    }
  `);
  const { basePath } = query.storiThemeData;

  return (
    <StyledButton props={{ primary }} to={basePath + to}>
      {children}
    </StyledButton>
  );
};

export default LinkButton;
