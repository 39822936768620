import React, { ReactNode, forwardRef } from 'react';
import styled from 'styled-components';

const StyledSectionHeader = styled.h1`
  border-left: solid 4px;
  border-color: ${({ theme }) => theme.primary};
  padding-left: 10px;
  font-weight: 300;
  font-size: 1.8rem;
  text-transform: uppercase;
`;
interface Props {
  children: ReactNode;
  as?: any;
  style?: any;
  ref?: any;
}

const SectionHeader = forwardRef<any, Props>((props: Props, ref) => {
  const { as, children, ...rest } = props;
  return (
    <StyledSectionHeader ref={ref} as={as} {...rest}>
      {children}
    </StyledSectionHeader>
  );
});

export default SectionHeader;
